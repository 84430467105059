import React from "react"

import "./project.css"
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css"
import ProjectPage from "../components/projectpage";
import ImageGallery from "react-image-gallery"
const images = [
    {
        original: require('../images/feetback/feetback-1.jpg'),
        thumbnail: require('../images/feetback/feetback-1.jpg')
    },
    {
        original: require('../images/feetback/feetback-2.jpg'),
        thumbnail: require('../images/feetback/feetback-2.jpg')
    },
    {
        original: require('../images/feetback/feetback-3.jpg'),
        thumbnail: require('../images/feetback/feetback-3.jpg')
    },
    {
        original: require('../images/feetback/feetback-4.jpg'),
        thumbnail: require('../images/feetback/feetback-4.jpg')
    },
    {
        original: require('../images/feetback/feetback-5.png'),
        thumbnail: require('../images/feetback/feetback-5.png')
    },
]

const IndexPage = ({ data }) => {
    return (<div className="background">
        <ProjectPage>
            <h1>Feetback</h1>
            <h4>August 2018 - May 2019</h4>
            <p>
                I worked on this project with a team of undergraduate students, as a part of the Grand Challenges program at Georgia Tech. In researching problem spaces in the area of Health, we found that falling among the elderly was a major problem that wasn't being addressed very well with current technology. We decided we wanted to create a cheaper, more effective option to help prevent falls among the elderly. In doing research into falling predictors, we found that a lot of predictors were related to someone's gait. We then started to develop a solution to this problem.
            </p>
            <p>
                With most of the current solutions being massive treadmills that cost thousands of dollars, we decided  to create a force sensitive shoe insole that would be able to detect the most important factors in determining fall risk, such as cadence and stride length. Throughout our creation process we consulted with an expert, a doctor at Emory whose research was in this exact area. We built the actual prototype, as well as the software in order to help determine the fall risk. You can view photos of our device below, along  with some of the outputs we had.
            </p>
            <ImageGallery showFullscreenButton={false} showPlayButton={false} items={images}></ImageGallery>
        </ProjectPage>
    </div>)
}

export default IndexPage
